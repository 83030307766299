import React, { memo } from 'react';
import Icon, { IconTypes } from '../Icon/Icon';
import ButtonLink, {
  ButtonLinkLinkOptions,
  ButtonLinkStyleOptions,
  ButtonLinkIconOptions,
} from '../ButtonLink/ButtonLink';
import './style.scss';

export interface OwnProps {
  className?: string;
  heading: string | React.ReactNode;
  children?: React.ReactNode;
  buttonOptions?: {
    to: string;
    label: string;
    iconOptions?: ButtonLinkIconOptions;
    linkOptions?: ButtonLinkLinkOptions;
    styleOptions?: ButtonLinkStyleOptions;
    ariaLabel?: string;
  };
  icon?: IconTypes;
}

type Props = OwnProps;

const InfoBlock: React.FC<Props> = ({
  heading,
  icon,
  buttonOptions,
  children,
  className = '',
}: Props) => {
  const getTitle = () => {
    return (
      <div className="InfoBlock__heading">
        {icon && <Icon type={icon} />}
        {typeof heading === 'string' ? <h5>{heading}</h5> : heading}
      </div>
    );
  };
  return (
    <div className={`InfoBlock ${className}`}>
      {getTitle()}
      <div>{children}</div>
      {buttonOptions && <ButtonLink {...buttonOptions}>{buttonOptions.label}</ButtonLink>}
    </div>
  );
};

export default memo(InfoBlock);
