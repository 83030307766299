import RouteKeys from '../../../config/RouteKeys';
import { PageMenuLinks } from './pageMenuTypes';
import { getPathFromRouteKey } from '../../shared/utils/getPathFromRouteKey';

const contactUsMenu: PageMenuLinks = [
  {
    to: getPathFromRouteKey(RouteKeys.CONTACT_US.CUSTOMER_SERVICE_SUPPORT),
    textKey: 'pageTitles.customerServiceSupport',
  },
  {
    to: getPathFromRouteKey(RouteKeys.CONTACT_US.COMPLAINTS_HANDLING),
    textKey: 'pageTitles.complaintHandling',
  },
  {
    to: getPathFromRouteKey(RouteKeys.BROKER_CENTRE.MEET_THE_TEAM),
    textKey: 'pageTitles.meetTheTeam',
  },
];

export default contactUsMenu;
